.root {
    @apply fixed bg-primary text-primary flex items-center inset-0 z-50 justify-center;
    background-color: rgba(0, 0, 0, 0.35);
  }
  
  .modal {
    @apply bg-black bg-opacity-60 py-2 px-4 rounded-xl w-48 h-48 flex flex-col justify-center;
  }
  
  .modal:focus {
    @apply outline-none;
  }
  