@font-face {
  font-family: 'icomoon';
  src: url('/fonts/icomoon.eot?wrm03w');
  src: url('/fonts/icomoon.eot?wrm03w#iefix') format('embedded-opentype'),
    url('/fonts/icomoon.ttf?wrm03w') format('truetype'),
    url('/fonts/icomoon.woff?wrm03w') format('woff'),
    url('/fonts/icomoon.svg?wrm03w#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Icon_Amount:before {
  content: '\e900';
}
.icon-Icon_Calendar:before {
  content: '\e901';
}
.icon-Icon_call:before {
  content: '\e902';
}
.icon-Icon_Camera:before {
  content: '\e903';
}
.icon-Icon_Chat:before {
  content: '\e904';
}
.icon-Icon_Credit-card-2:before {
  content: '\e905';
}
.icon-Icon_Delivery:before {
  content: '\e906';
}
.icon-Icon_Discount:before {
  content: '\e907';
}
.icon-Icon_email:before {
  content: '\e908';
}
.icon-icon_Home:before {
  content: '\e909';
}
.icon-Icon_Location:before {
  content: '\e90a';
}
.icon-Icon_More:before {
  content: '\e90b';
}
.icon-Icon_Notes:before {
  content: '\e90c';
}
.icon-Icon_Orders:before {
  content: '\e90d';
}
.icon-Icon_Products:before {
  content: '\e90e';
}
.icon-Icon_Receipt:before {
  content: '\e90f';
}
.icon-Icon_Report:before {
  content: '\e910';
}
.icon-Icon_Search:before {
  content: '\e911';
}
.icon-Icon_Stock:before {
  content: '\e912';
}
.icon-Icon_Tag:before {
  content: '\e913';
}
.icon-Icon_Team:before {
  content: '\e914';
}
.icon-Icon_Type:before {
  content: '\e915';
}
.icon-Logo:before {
  content: '\e916';
}
.icon-Symbol_AKA:before {
  content: '\e917';
}
.icon-Symbol_Export:before {
  content: '\e918';
}
.icon-Symbol_Level:before {
  content: '\e919';
}
.icon-Symbol_Menu:before {
  content: '\e91a';
}
.icon-Symbol_WeChat:before {
  content: '\e91b';
}
.icon-Icon_Search1:before {
  content: '\a842';
}
.icon-aaIconImageClicked:before {
  content: '\aa02';
}
.icon-aaIconVRClicked:before {
  content: '\aa03';
}
.icon-aaIconVideoClicked:before {
  content: '\aa04';
}
.icon-aaIconImage:before {
  content: '\aa05';
}
.icon-aaIconVR:before {
  content: '\aa06';
}
.icon-aaIconVideo:before {
  content: '\aa07';
}
.icon-aaIconProfile:before {
  content: '\aa08';
}
.icon-aaIconDownload:before {
  content: '\aa09';
}
.icon-aaIconLocating:before {
  content: '\aa10';
}
.icon-aaIconChangeCode:before {
  content: '\aa11';
}
.icon-aaIconLink:before {
  content: '\aa12';
}
.icon-aaIconVerify:before {
  content: '\aa13';
}
.icon-aaIconPurchaseRecord:before {
  content: '\aa14';
}
.icon-aaIconConvenionSet:before {
  content: '\aa15';
}
.icon-aaIconUsefulArticle:before {
  content: '\aa16';
}
.icon-aaIconPropertiesPrice:before {
  content: '\aa17';
}
.icon-aaIconTraffic:before {
  content: '\aa18';
}
.icon-aaIconSchool:before {
  content: '\aa19';
}
.icon-aaIconCriminal:before {
  content: '\aa20';
}
.icon-aaIconDiscover:before {
  content: '\aa27';
}
.icon-aaIconIndexClicked:before {
  content: '\aa28';
}
.icon-aaIconDiscover1:before {
  content: '\aa29';
}
.icon-aaIconOffer:before {
  content: '\aa30';
}
.icon-aaIconFailed:before {
  content: '\aa31';
}
.icon-aaIconEmail:before {
  content: '\aa32';
}
.icon-aaIconPassword:before {
  content: '\aa33';
}
.icon-aaIconSuccess:before {
  content: '\aa34';
}
.icon-aaIconListViewClicked:before {
  content: '\aa35';
}
.icon-aaIconListView:before {
  content: '\aa36';
}
.icon-aaIconCompass:before {
  content: '\aa37';
}
.icon-aaIconCalendar:before {
  content: '\aa38';
}
.icon-aaIconMapClicked:before {
  content: '\aa39';
}
.icon-abIconProfileClicked:before {
  content: '\aa42';
}
.icon-aaIconManage:before {
  content: '\aa44';
}
.icon-aaIconMessage:before {
  content: '\aa45';
}
.icon-aaIconFavoriteClicked:before {
  content: '\aa46';
}
.icon-aaIconFavorite:before {
  content: '\aa47';
}
.icon-aaIconNewPostClicked:before {
  content: '\aa48';
}
.icon-aaIconNewPost:before {
  content: '\aa49';
}
.icon-aaIconWeibo:before {
  content: '\aa50';
}
.icon-aaIconWechat:before {
  content: '\aa51';
}
.icon-aaIconFacebook:before {
  content: '\aa52';
}
.icon-aaIconTwitter:before {
  content: '\aa53';
}
.icon-aaIconCamera:before {
  content: '\aa54';
}
.icon-aaIconCameraClicked:before {
  content: '\aa55';
}
.icon-aaIconShare:before {
  content: '\aa56';
}
.icon-aaIconCode:before {
  content: '\aa57';
}
.icon-aaIconManagePropertiesClicked:before {
  content: '\aa58';
}
.icon-aaIconPlumbingClicked:before {
  content: '\aa59';
}
.icon-aaIconHouseCleaningClicked:before {
  content: '\aa60';
}
.icon-aaIconBGCheckClicked:before {
  content: '\aa61';
}
.icon-aaIconRentCollectClicked:before {
  content: '\aa62';
}
.icon-aaIconContractClicked:before {
  content: '\aa63';
}
.icon-aaIconManageProperties:before {
  content: '\aa64';
}
.icon-aaIconPlumbing:before {
  content: '\aa65';
}
.icon-aaIconHouseCleaning:before {
  content: '\aa66';
}
.icon-aaIconBGCheck:before {
  content: '\aa67';
}
.icon-aaIconRentCollect:before {
  content: '\aa68';
}
.icon-aaIconContract:before {
  content: '\aa69';
}
.icon-aaIconCellPhone:before {
  content: '\aa70';
}
.icon-aaIconTime:before {
  content: '\aa71';
}
.icon-aaIconCallClicked:before {
  content: '\aa72';
}
.icon-aaIconCall:before {
  content: '\aa73';
}
.icon-aaIconEmail1:before {
  content: '\aa74';
}
.icon-aaIconSmokeClicked:before {
  content: '\aa75';
}
.icon-aaIconSmoke:before {
  content: '\aa76';
}
.icon-aaIconPetsClicked:before {
  content: '\aa77';
}
.icon-aaIconPets:before {
  content: '\aa78';
}
.icon-aaIconWheelchairClicked:before {
  content: '\aa79';
}
.icon-aaIconWheelchair:before {
  content: '\aa80';
}
.icon-aaIconStorageClicked:before {
  content: '\aa81';
}
.icon-aaIconStorage:before {
  content: '\aa82';
}
.icon-aaIconMaintenanceClicked:before {
  content: '\aa83';
}
.icon-aaIconGasClicked:before {
  content: '\aa84';
}
.icon-aaIconWaterClicked:before {
  content: '\aa85';
}
.icon-aaIconHeaterClicked:before {
  content: '\aa86';
}
.icon-aaIconWifiClicked:before {
  content: '\aa87';
}
.icon-aaIconMaintenance:before {
  content: '\aa88';
}
.icon-aaIconWifi:before {
  content: '\aa89';
}
.icon-aaIconGas:before {
  content: '\aa8a';
}
.icon-aaIconWater:before {
  content: '\aa90';
}
.icon-aaIconHeater:before {
  content: '\aa91';
}
.icon-aaIconACClickded:before {
  content: '\aa92';
}
.icon-aaIconAC:before {
  content: '\aa93';
}
.icon-aaIconPowerClicked:before {
  content: '\aa94';
}
.icon-aaIconPower:before {
  content: '\aa95';
}
.icon-aaIconFurnitureClicked:before {
  content: '\aa96';
}
.icon-aaIconFurniture:before {
  content: '\aa97';
}
.icon-aaIconParkingClicked:before {
  content: '\aa99';
}
.icon-abIconParking:before {
  content: '\ab01';
}
.icon-abIconLaundryClicked:before {
  content: '\ab02';
}
.icon-abIconLaundry:before {
  content: '\ab03';
}
.icon-abIconStoreyClicked:before {
  content: '\ab04';
}
.icon-abIconStorey:before {
  content: '\ab05';
}
.icon-abIconDeposit:before {
  content: '\ab06';
}
.icon-abIconYearBuilt:before {
  content: '\ab07';
}
.icon-abIconPhone:before {
  content: '\ab08';
}
.icon-abIconHousingSize:before {
  content: '\ab09';
}
.icon-abIconBuilding:before {
  content: '\ab0a';
}
.icon-abIconLocation:before {
  content: '\ab10';
}
.icon-abIconButler:before {
  content: '\ab11';
}
.icon-aaIconIndex:before {
  content: '\ab12';
}
.icon-abIconProfile:before {
  content: '\ab13';
}
.icon-abIconChat:before {
  content: '\ab14';
}
.icon-abIconEmailClicked:before {
  content: '\ab15';
}
.icon-abIconVIP:before {
  content: '\ab16';
}
.icon-abIconWebsite:before {
  content: '\ab17';
}
.icon-abIconTrain:before {
  content: '\ab18';
}
.icon-abIconBus:before {
  content: '\ab19';
}
.icon-abIconMarket:before {
  content: '\ab20';
}
.icon-abIconMedical:before {
  content: '\ab21';
}
.icon-abIconPark:before {
  content: '\ab22';
}
.icon-abIconRestaurant:before {
  content: '\ab23';
}
.icon-abIconShopping:before {
  content: '\ab24';
}
.icon-abIconExpand:before {
  content: '\ab25';
}
.icon-abIconMagicTools:before {
  content: '\ab26';
}
.icon-abIconMassiveSource:before {
  content: '\ab27';
}
.icon-abIconOptimalService:before {
  content: '\ab28';
}
.icon-abIconUserScreening:before {
  content: '\ab29';
}
.icon-abIconButlerService:before {
  content: '\ab30';
}
.icon-aaIconManageClicked:before {
  content: '\ab31';
}
.icon-aaIconOfferClicked:before {
  content: '\ab32';
}
.icon-abIconCreditCard:before {
  content: '\ab33';
}
.icon-abIconWeChatPay:before {
  content: '\ab34';
}
.icon-Icon_minus:before {
  content: '\e91c';
}
.icon-Icon_plus:before {
  content: '\e91d';
}
.icon-Icon_cart1:before {
  content: '\e91e';
}
.icon-aaIconFilter:before {
  content: '\e91f';
}
.icon-aaIconLock:before {
  content: '\e920';
}
.icon-aaIconAbout:before {
  content: '\e921';
}
.icon-abIconBound:before {
  content: '\e922';
}
.icon-abIconUpload:before {
  content: '\e923';
}
.icon-abIconEdit:before {
  content: '\e924';
}
.icon-abIconDelete:before {
  content: '\e925';
}
.icon-aaIconIncludeBalcony:before {
  content: '\e926';
}
.icon-aaIconIncludeGym:before {
  content: '\e927';
}
.icon-aaIconList:before {
  content: '\e928';
}
.icon-aaIconMap:before {
  content: '\e929';
}
.icon-aaIconDiscoverClicked:before {
  content: '\e92a';
}
.icon-home1:before {
  content: '\e92b';
}
.icon-home21:before {
  content: '\e92c';
}
.icon-home31:before {
  content: '\e92d';
}
.icon-office1:before {
  content: '\e92e';
}
.icon-newspaper1:before {
  content: '\e92f';
}
.icon-pencil1:before {
  content: '\e930';
}
.icon-pencil21:before {
  content: '\e931';
}
.icon-quill1:before {
  content: '\e932';
}
.icon-pen1:before {
  content: '\e933';
}
.icon-blog1:before {
  content: '\e934';
}
.icon-eyedropper1:before {
  content: '\e935';
}
.icon-droplet1:before {
  content: '\e936';
}
.icon-paint-format1:before {
  content: '\e937';
}
.icon-image1:before {
  content: '\e938';
}
.icon-images1:before {
  content: '\e939';
}
.icon-camera1:before {
  content: '\e93a';
}
.icon-headphones1:before {
  content: '\e93b';
}
.icon-music1:before {
  content: '\e93c';
}
.icon-play1:before {
  content: '\e93d';
}
.icon-film1:before {
  content: '\e93e';
}
.icon-video-camera1:before {
  content: '\e93f';
}
.icon-dice1:before {
  content: '\e940';
}
.icon-pacman1:before {
  content: '\e941';
}
.icon-spades1:before {
  content: '\e942';
}
.icon-clubs1:before {
  content: '\e943';
}
.icon-diamonds1:before {
  content: '\e944';
}
.icon-bullhorn1:before {
  content: '\e945';
}
.icon-connection1:before {
  content: '\e946';
}
.icon-podcast1:before {
  content: '\e947';
}
.icon-feed1:before {
  content: '\e948';
}
.icon-mic1:before {
  content: '\e949';
}
.icon-book1:before {
  content: '\e94a';
}
.icon-books1:before {
  content: '\e94b';
}
.icon-library1:before {
  content: '\e94c';
}
.icon-file-text1:before {
  content: '\e94d';
}
.icon-profile1:before {
  content: '\e94e';
}
.icon-file-empty1:before {
  content: '\e94f';
}
.icon-files-empty1:before {
  content: '\e950';
}
.icon-file-text21:before {
  content: '\e951';
}
.icon-file-picture1:before {
  content: '\e952';
}
.icon-file-music1:before {
  content: '\e953';
}
.icon-file-play1:before {
  content: '\e954';
}
.icon-file-video1:before {
  content: '\e955';
}
.icon-file-zip1:before {
  content: '\e956';
}
.icon-copy1:before {
  content: '\e957';
}
.icon-paste1:before {
  content: '\e958';
}
.icon-stack1:before {
  content: '\e959';
}
.icon-folder1:before {
  content: '\e95a';
}
.icon-folder-open1:before {
  content: '\e95b';
}
.icon-folder-plus1:before {
  content: '\e95c';
}
.icon-folder-minus1:before {
  content: '\e95d';
}
.icon-folder-download1:before {
  content: '\e95e';
}
.icon-folder-upload1:before {
  content: '\e95f';
}
.icon-price-tag1:before {
  content: '\e960';
}
.icon-price-tags1:before {
  content: '\e961';
}
.icon-barcode1:before {
  content: '\e962';
}
.icon-qrcode1:before {
  content: '\e963';
}
.icon-ticket1:before {
  content: '\e964';
}
.icon-cart1:before {
  content: '\e965';
}
.icon-coin-dollar1:before {
  content: '\e966';
}
.icon-coin-euro1:before {
  content: '\e967';
}
.icon-Icon_Plus:before {
  content: '\e968';
}
.icon-coin-pound1:before {
  content: '\e969';
}
.icon-coin-yen1:before {
  content: '\e96a';
}
.icon-credit-card1:before {
  content: '\e96b';
}
.icon-calculator1:before {
  content: '\e96c';
}
.icon-lifebuoy1:before {
  content: '\e96d';
}
.icon-phone1:before {
  content: '\e96e';
}
.icon-phone-hang-up1:before {
  content: '\e96f';
}
.icon-address-book1:before {
  content: '\e970';
}
.icon-envelop1:before {
  content: '\e971';
}
.icon-pushpin1:before {
  content: '\e972';
}
.icon-location1:before {
  content: '\e973';
}
.icon-location21:before {
  content: '\e974';
}
.icon-compass1:before {
  content: '\e975';
}
.icon-compass21:before {
  content: '\e976';
}
.icon-map1:before {
  content: '\e977';
}
.icon-map21:before {
  content: '\e978';
}
.icon-history1:before {
  content: '\e979';
}
.icon-clock1:before {
  content: '\e97a';
}
.icon-clock21:before {
  content: '\e97b';
}
.icon-alarm1:before {
  content: '\e97c';
}
.icon-bell1:before {
  content: '\e97d';
}
.icon-stopwatch1:before {
  content: '\e97e';
}
.icon-calendar1:before {
  content: '\e97f';
}
.icon-printer1:before {
  content: '\e980';
}
.icon-keyboard1:before {
  content: '\e981';
}
.icon-display1:before {
  content: '\e982';
}
.icon-laptop1:before {
  content: '\e983';
}
.icon-mobile1:before {
  content: '\e984';
}
.icon-mobile21:before {
  content: '\e985';
}
.icon-tablet1:before {
  content: '\e986';
}
.icon-tv1:before {
  content: '\e987';
}
.icon-drawer1:before {
  content: '\e988';
}
.icon-drawer21:before {
  content: '\e989';
}
.icon-box-add1:before {
  content: '\e98a';
}
.icon-box-remove1:before {
  content: '\e98b';
}
.icon-download1:before {
  content: '\e98c';
}
.icon-upload1:before {
  content: '\e98d';
}
.icon-floppy-disk1:before {
  content: '\e98e';
}
.icon-drive1:before {
  content: '\e98f';
}
.icon-database1:before {
  content: '\e990';
}
.icon-undo1:before {
  content: '\e991';
}
.icon-redo1:before {
  content: '\e992';
}
.icon-undo21:before {
  content: '\e993';
}
.icon-redo21:before {
  content: '\e994';
}
.icon-forward1:before {
  content: '\e995';
}
.icon-reply1:before {
  content: '\e996';
}
.icon-bubble1:before {
  content: '\e997';
}
.icon-bubbles1:before {
  content: '\e998';
}
.icon-bubbles21:before {
  content: '\e999';
}
.icon-bubble21:before {
  content: '\e99a';
}
.icon-bubbles31:before {
  content: '\e99b';
}
.icon-bubbles41:before {
  content: '\e99c';
}
.icon-user1:before {
  content: '\e99d';
}
.icon-users1:before {
  content: '\e99e';
}
.icon-user-plus1:before {
  content: '\e99f';
}
.icon-user-minus1:before {
  content: '\e9a0';
}
.icon-user-check1:before {
  content: '\e9a1';
}
.icon-user-tie1:before {
  content: '\e9a2';
}
.icon-quotes-left1:before {
  content: '\e9a3';
}
.icon-quotes-right1:before {
  content: '\e9a4';
}
.icon-hour-glass1:before {
  content: '\e9a5';
}
.icon-spinner1:before {
  content: '\e9a6';
}
.icon-spinner21:before {
  content: '\e9a7';
}
.icon-spinner31:before {
  content: '\e9a8';
}
.icon-spinner41:before {
  content: '\e9a9';
}
.icon-spinner51:before {
  content: '\e9aa';
}
.icon-spinner61:before {
  content: '\e9ab';
}
.icon-spinner71:before {
  content: '\e9ac';
}
.icon-spinner81:before {
  content: '\e9ad';
}
.icon-spinner91:before {
  content: '\e9ae';
}
.icon-spinner101:before {
  content: '\e9af';
}
.icon-spinner111:before {
  content: '\e9b0';
}
.icon-binoculars1:before {
  content: '\e9b1';
}
.icon-search1:before {
  content: '\e9b2';
}
.icon-zoom-in1:before {
  content: '\e9b3';
}
.icon-zoom-out1:before {
  content: '\e9b4';
}
.icon-enlarge1:before {
  content: '\e9b5';
}
.icon-shrink1:before {
  content: '\e9b6';
}
.icon-enlarge21:before {
  content: '\e9b7';
}
.icon-shrink21:before {
  content: '\e9b8';
}
.icon-key1:before {
  content: '\e9b9';
}
.icon-key21:before {
  content: '\e9ba';
}
.icon-lock1:before {
  content: '\e9bb';
}
.icon-unlocked1:before {
  content: '\e9bc';
}
.icon-wrench1:before {
  content: '\e9bd';
}
.icon-equalizer1:before {
  content: '\e9be';
}
.icon-equalizer21:before {
  content: '\e9bf';
}
.icon-cog1:before {
  content: '\e9c0';
}
.icon-cogs1:before {
  content: '\e9c1';
}
.icon-hammer1:before {
  content: '\e9c2';
}
.icon-magic-wand1:before {
  content: '\e9c3';
}
.icon-aid-kit1:before {
  content: '\e9c4';
}
.icon-bug1:before {
  content: '\e9c5';
}
.icon-pie-chart1:before {
  content: '\e9c6';
}
.icon-stats-dots1:before {
  content: '\e9c7';
}
.icon-stats-bars1:before {
  content: '\e9c8';
}
.icon-stats-bars21:before {
  content: '\e9c9';
}
.icon-trophy1:before {
  content: '\e9ca';
}
.icon-gift1:before {
  content: '\e9cb';
}
.icon-glass1:before {
  content: '\e9cc';
}
.icon-glass21:before {
  content: '\e9cd';
}
.icon-mug1:before {
  content: '\e9ce';
}
.icon-spoon-knife1:before {
  content: '\e9cf';
}
.icon-leaf1:before {
  content: '\e9d0';
}
.icon-rocket1:before {
  content: '\e9d1';
}
.icon-meter1:before {
  content: '\e9d2';
}
.icon-meter21:before {
  content: '\e9d3';
}
.icon-hammer21:before {
  content: '\e9d4';
}
.icon-fire1:before {
  content: '\e9d5';
}
.icon-lab1:before {
  content: '\e9d6';
}
.icon-magnet1:before {
  content: '\e9d7';
}
.icon-bin1:before {
  content: '\e9d8';
}
.icon-bin21:before {
  content: '\e9d9';
}
.icon-briefcase1:before {
  content: '\e9da';
}
.icon-airplane1:before {
  content: '\e9db';
}
.icon-truck1:before {
  content: '\e9dc';
}
.icon-road1:before {
  content: '\e9dd';
}
.icon-accessibility1:before {
  content: '\e9de';
}
.icon-target1:before {
  content: '\e9df';
}
.icon-shield1:before {
  content: '\e9e0';
}
.icon-power1:before {
  content: '\e9e1';
}
.icon-switch1:before {
  content: '\e9e2';
}
.icon-power-cord1:before {
  content: '\e9e3';
}
.icon-clipboard1:before {
  content: '\e9e4';
}
.icon-list-numbered1:before {
  content: '\e9e5';
}
.icon-list1:before {
  content: '\e9e6';
}
.icon-list21:before {
  content: '\e9e7';
}
.icon-tree1:before {
  content: '\e9e8';
}
.icon-menu1:before {
  content: '\e9e9';
}
.icon-menu21:before {
  content: '\e9ea';
}
.icon-menu31:before {
  content: '\e9eb';
}
.icon-menu41:before {
  content: '\e9ec';
}
.icon-cloud1:before {
  content: '\e9ed';
}
.icon-cloud-download1:before {
  content: '\e9ee';
}
.icon-cloud-upload1:before {
  content: '\e9ef';
}
.icon-cloud-check1:before {
  content: '\e9f0';
}
.icon-download21:before {
  content: '\e9f1';
}
.icon-upload21:before {
  content: '\e9f2';
}
.icon-download31:before {
  content: '\e9f3';
}
.icon-upload31:before {
  content: '\e9f4';
}
.icon-sphere1:before {
  content: '\e9f5';
}
.icon-earth1:before {
  content: '\e9f6';
}
.icon-link1:before {
  content: '\e9f7';
}
.icon-flag1:before {
  content: '\e9f8';
}
.icon-attachment1:before {
  content: '\e9f9';
}
.icon-eye1:before {
  content: '\e9fa';
}
.icon-eye-plus1:before {
  content: '\e9fb';
}
.icon-eye-minus1:before {
  content: '\e9fc';
}
.icon-eye-blocked1:before {
  content: '\e9fd';
}
.icon-bookmark1:before {
  content: '\e9fe';
}
.icon-bookmarks1:before {
  content: '\e9ff';
}
.icon-sun1:before {
  content: '\ea00';
}
.icon-contrast1:before {
  content: '\ea01';
}
.icon-brightness-contrast1:before {
  content: '\ea02';
}
.icon-star-empty1:before {
  content: '\ea03';
}
.icon-star-half1:before {
  content: '\ea04';
}
.icon-star-full1:before {
  content: '\ea05';
}
.icon-heart1:before {
  content: '\ea06';
}
.icon-heart-broken1:before {
  content: '\ea07';
}
.icon-man1:before {
  content: '\ea08';
}
.icon-woman1:before {
  content: '\ea09';
}
.icon-man-woman1:before {
  content: '\ea0a';
}
.icon-happy1:before {
  content: '\ea0b';
}
.icon-happy21:before {
  content: '\ea0c';
}
.icon-smile1:before {
  content: '\ea0d';
}
.icon-smile21:before {
  content: '\ea0e';
}
.icon-tongue1:before {
  content: '\ea0f';
}
.icon-tongue21:before {
  content: '\ea10';
}
.icon-sad1:before {
  content: '\ea11';
}
.icon-sad21:before {
  content: '\ea12';
}
.icon-wink1:before {
  content: '\ea13';
}
.icon-wink21:before {
  content: '\ea14';
}
.icon-grin1:before {
  content: '\ea15';
}
.icon-grin21:before {
  content: '\ea16';
}
.icon-cool1:before {
  content: '\ea17';
}
.icon-cool21:before {
  content: '\ea18';
}
.icon-angry1:before {
  content: '\ea19';
}
.icon-angry21:before {
  content: '\ea1a';
}
.icon-evil1:before {
  content: '\ea1b';
}
.icon-evil21:before {
  content: '\ea1c';
}
.icon-shocked1:before {
  content: '\ea1d';
}
.icon-shocked21:before {
  content: '\ea1e';
}
.icon-baffled1:before {
  content: '\ea1f';
}
.icon-baffled21:before {
  content: '\ea20';
}
.icon-confused1:before {
  content: '\ea21';
}
.icon-confused21:before {
  content: '\ea22';
}
.icon-neutral1:before {
  content: '\ea23';
}
.icon-neutral21:before {
  content: '\ea24';
}
.icon-hipster1:before {
  content: '\ea25';
}
.icon-hipster21:before {
  content: '\ea26';
}
.icon-wondering1:before {
  content: '\ea27';
}
.icon-wondering21:before {
  content: '\ea28';
}
.icon-sleepy1:before {
  content: '\ea29';
}
.icon-sleepy21:before {
  content: '\ea2a';
}
.icon-frustrated1:before {
  content: '\ea2b';
}
.icon-frustrated21:before {
  content: '\ea2c';
}
.icon-crying1:before {
  content: '\ea2d';
}
.icon-crying21:before {
  content: '\ea2e';
}
.icon-point-up1:before {
  content: '\ea2f';
}
.icon-point-right1:before {
  content: '\ea30';
}
.icon-point-down1:before {
  content: '\ea31';
}
.icon-point-left1:before {
  content: '\ea32';
}
.icon-warning1:before {
  content: '\ea33';
}
.icon-notification1:before {
  content: '\ea34';
}
.icon-question1:before {
  content: '\ea35';
}
.icon-plus1:before {
  content: '\ea36';
}
.icon-minus1:before {
  content: '\ea37';
}
.icon-info1:before {
  content: '\ea38';
}
.icon-cancel-circle1:before {
  content: '\ea39';
}
.icon-blocked1:before {
  content: '\ea3a';
}
.icon-cross1:before {
  content: '\ea3b';
}
.icon-checkmark1:before {
  content: '\ea3c';
}
.icon-checkmark21:before {
  content: '\ea3d';
}
.icon-spell-check1:before {
  content: '\ea3e';
}
.icon-enter1:before {
  content: '\ea3f';
}
.icon-exit1:before {
  content: '\ea40';
}
.icon-play21:before {
  content: '\ea41';
}
.icon-pause1:before {
  content: '\ea42';
}
.icon-stop1:before {
  content: '\ea43';
}
.icon-previous1:before {
  content: '\ea44';
}
.icon-next1:before {
  content: '\ea45';
}
.icon-backward1:before {
  content: '\ea46';
}
.icon-forward21:before {
  content: '\ea47';
}
.icon-play31:before {
  content: '\ea48';
}
.icon-pause21:before {
  content: '\ea49';
}
.icon-stop21:before {
  content: '\ea4a';
}
.icon-backward21:before {
  content: '\ea4b';
}
.icon-forward31:before {
  content: '\ea4c';
}
.icon-first1:before {
  content: '\ea4d';
}
.icon-last1:before {
  content: '\ea4e';
}
.icon-previous21:before {
  content: '\ea4f';
}
.icon-next21:before {
  content: '\ea50';
}
.icon-eject1:before {
  content: '\ea51';
}
.icon-volume-high1:before {
  content: '\ea52';
}
.icon-volume-medium1:before {
  content: '\ea53';
}
.icon-volume-low1:before {
  content: '\ea54';
}
.icon-volume-mute1:before {
  content: '\ea55';
}
.icon-volume-mute21:before {
  content: '\ea56';
}
.icon-volume-increase1:before {
  content: '\ea57';
}
.icon-volume-decrease1:before {
  content: '\ea58';
}
.icon-loop1:before {
  content: '\ea59';
}
.icon-loop21:before {
  content: '\ea5a';
}
.icon-infinite1:before {
  content: '\ea5b';
}
.icon-shuffle1:before {
  content: '\ea5c';
}
.icon-arrow-up-left1:before {
  content: '\ea5d';
}
.icon-arrow-up1:before {
  content: '\ea5e';
}
.icon-arrow-up-right1:before {
  content: '\ea5f';
}
.icon-arrow-right1:before {
  content: '\ea60';
}
.icon-arrow-down-right1:before {
  content: '\ea61';
}
.icon-arrow-down1:before {
  content: '\ea62';
}
.icon-arrow-down-left1:before {
  content: '\ea63';
}
.icon-arrow-left1:before {
  content: '\ea64';
}
.icon-arrow-up-left21:before {
  content: '\ea65';
}
.icon-arrow-up21:before {
  content: '\ea66';
}
.icon-arrow-up-right21:before {
  content: '\ea67';
}
.icon-arrow-right21:before {
  content: '\ea68';
}
.icon-arrow-down-right21:before {
  content: '\ea69';
}
.icon-arrow-down21:before {
  content: '\ea6a';
}
.icon-arrow-down-left21:before {
  content: '\ea6b';
}
.icon-arrow-left21:before {
  content: '\ea6c';
}
.icon-circle-up1:before {
  content: '\ea6d';
}
.icon-circle-right1:before {
  content: '\ea6e';
}
.icon-circle-down1:before {
  content: '\ea6f';
}
.icon-circle-left1:before {
  content: '\ea70';
}
.icon-tab1:before {
  content: '\ea71';
}
.icon-move-up1:before {
  content: '\ea72';
}
.icon-move-down1:before {
  content: '\ea73';
}
.icon-sort-alpha-asc1:before {
  content: '\ea74';
}
.icon-sort-alpha-desc1:before {
  content: '\ea75';
}
.icon-sort-numeric-asc1:before {
  content: '\ea76';
}
.icon-sort-numberic-desc1:before {
  content: '\ea77';
}
.icon-sort-amount-asc1:before {
  content: '\ea78';
}
.icon-sort-amount-desc1:before {
  content: '\ea79';
}
.icon-command1:before {
  content: '\ea7a';
}
.icon-shift1:before {
  content: '\ea7b';
}
.icon-ctrl1:before {
  content: '\ea7c';
}
.icon-opt1:before {
  content: '\ea7d';
}
.icon-checkbox-checked1:before {
  content: '\ea7e';
}
.icon-checkbox-unchecked1:before {
  content: '\ea7f';
}
.icon-radio-checked1:before {
  content: '\ea80';
}
.icon-radio-checked21:before {
  content: '\ea81';
}
.icon-radio-unchecked1:before {
  content: '\ea82';
}
.icon-crop1:before {
  content: '\ea83';
}
.icon-make-group1:before {
  content: '\ea84';
}
.icon-ungroup1:before {
  content: '\ea85';
}
.icon-scissors1:before {
  content: '\ea86';
}
.icon-filter1:before {
  content: '\ea87';
}
.icon-font1:before {
  content: '\ea88';
}
.icon-ligature1:before {
  content: '\ea89';
}
.icon-ligature21:before {
  content: '\ea8a';
}
.icon-text-height1:before {
  content: '\ea8b';
}
.icon-text-width1:before {
  content: '\ea8c';
}
.icon-font-size1:before {
  content: '\ea8d';
}
.icon-bold1:before {
  content: '\ea8e';
}
.icon-underline1:before {
  content: '\ea8f';
}
.icon-italic1:before {
  content: '\ea90';
}
.icon-strikethrough1:before {
  content: '\ea91';
}
.icon-omega1:before {
  content: '\ea92';
}
.icon-sigma1:before {
  content: '\ea93';
}
.icon-page-break1:before {
  content: '\ea94';
}
.icon-superscript1:before {
  content: '\ea95';
}
.icon-subscript1:before {
  content: '\ea96';
}
.icon-superscript21:before {
  content: '\ea97';
}
.icon-subscript21:before {
  content: '\ea98';
}
.icon-text-color1:before {
  content: '\ea99';
}
.icon-pagebreak1:before {
  content: '\ea9a';
}
.icon-clear-formatting1:before {
  content: '\ea9b';
}
.icon-table1:before {
  content: '\ea9c';
}
.icon-table21:before {
  content: '\ea9d';
}
.icon-insert-template1:before {
  content: '\ea9e';
}
.icon-pilcrow1:before {
  content: '\ea9f';
}
.icon-ltr1:before {
  content: '\eaa0';
}
.icon-rtl1:before {
  content: '\eaa1';
}
.icon-section1:before {
  content: '\eaa2';
}
.icon-paragraph-left1:before {
  content: '\eaa3';
}
.icon-paragraph-center1:before {
  content: '\eaa4';
}
.icon-paragraph-right1:before {
  content: '\eaa5';
}
.icon-paragraph-justify1:before {
  content: '\eaa6';
}
.icon-indent-increase1:before {
  content: '\eaa7';
}
.icon-indent-decrease1:before {
  content: '\eaa8';
}
.icon-share1:before {
  content: '\eaa9';
}
.icon-new-tab1:before {
  content: '\eaaa';
}
.icon-embed1:before {
  content: '\eaab';
}
.icon-embed21:before {
  content: '\eaac';
}
.icon-terminal1:before {
  content: '\eaad';
}
.icon-share21:before {
  content: '\eaae';
}
.icon-mail1:before {
  content: '\eaaf';
}
.icon-mail21:before {
  content: '\eab0';
}
.icon-mail31:before {
  content: '\eab1';
}
.icon-mail41:before {
  content: '\eab2';
}
.icon-amazon1:before {
  content: '\eab3';
}
.icon-google1:before {
  content: '\eab4';
}
.icon-google21:before {
  content: '\eab5';
}
.icon-google31:before {
  content: '\eab6';
}
.icon-google-plus1:before {
  content: '\eab7';
}
.icon-google-plus21:before {
  content: '\eab8';
}
.icon-google-plus31:before {
  content: '\eab9';
}
.icon-hangouts1:before {
  content: '\eaba';
}
.icon-google-drive1:before {
  content: '\eabb';
}
.icon-facebook1:before {
  content: '\eabc';
}
.icon-facebook21:before {
  content: '\eabd';
}
.icon-instagram1:before {
  content: '\eabe';
}
.icon-whatsapp1:before {
  content: '\eabf';
}
.icon-spotify1:before {
  content: '\eac0';
}
.icon-telegram1:before {
  content: '\eac1';
}
.icon-twitter1:before {
  content: '\eac2';
}
.icon-vine1:before {
  content: '\eac3';
}
.icon-vk1:before {
  content: '\eac4';
}
.icon-renren1:before {
  content: '\eac5';
}
.icon-sina-weibo1:before {
  content: '\eac6';
}
.icon-rss1:before {
  content: '\eac7';
}
.icon-rss21:before {
  content: '\eac8';
}
.icon-youtube1:before {
  content: '\eac9';
}
.icon-youtube21:before {
  content: '\eaca';
}
.icon-twitch1:before {
  content: '\eacb';
}
.icon-vimeo1:before {
  content: '\eacc';
}
.icon-vimeo21:before {
  content: '\eacd';
}
.icon-lanyrd1:before {
  content: '\eace';
}
.icon-flickr1:before {
  content: '\eacf';
}
.icon-flickr21:before {
  content: '\ead0';
}
.icon-flickr31:before {
  content: '\ead1';
}
.icon-flickr41:before {
  content: '\ead2';
}
.icon-dribbble1:before {
  content: '\ead3';
}
.icon-behance1:before {
  content: '\ead4';
}
.icon-behance21:before {
  content: '\ead5';
}
.icon-deviantart1:before {
  content: '\ead6';
}
.icon-500px1:before {
  content: '\ead7';
}
.icon-steam1:before {
  content: '\ead8';
}
.icon-steam21:before {
  content: '\ead9';
}
.icon-dropbox1:before {
  content: '\eada';
}
.icon-onedrive1:before {
  content: '\eadb';
}
.icon-github1:before {
  content: '\eadc';
}
.icon-npm1:before {
  content: '\eadd';
}
.icon-basecamp1:before {
  content: '\eade';
}
.icon-trello1:before {
  content: '\eadf';
}
.icon-wordpress1:before {
  content: '\eae0';
}
.icon-joomla1:before {
  content: '\eae1';
}
.icon-ello1:before {
  content: '\eae2';
}
.icon-blogger1:before {
  content: '\eae3';
}
.icon-blogger21:before {
  content: '\eae4';
}
.icon-tumblr1:before {
  content: '\eae5';
}
.icon-tumblr21:before {
  content: '\eae6';
}
.icon-yahoo1:before {
  content: '\eae7';
}
.icon-yahoo21:before {
  content: '\eae8';
}
.icon-tux1:before {
  content: '\eae9';
}
.icon-appleinc1:before {
  content: '\eaea';
}
.icon-finder1:before {
  content: '\eaeb';
}
.icon-android1:before {
  content: '\eaec';
}
.icon-windows1:before {
  content: '\eaed';
}
.icon-windows81:before {
  content: '\eaee';
}
.icon-soundcloud1:before {
  content: '\eaef';
}
.icon-soundcloud21:before {
  content: '\eaf0';
}
.icon-skype1:before {
  content: '\eaf1';
}
.icon-reddit1:before {
  content: '\eaf2';
}
.icon-hackernews1:before {
  content: '\eaf3';
}
.icon-wikipedia1:before {
  content: '\eaf4';
}
.icon-linkedin1:before {
  content: '\eaf5';
}
.icon-linkedin21:before {
  content: '\eaf6';
}
.icon-lastfm1:before {
  content: '\eaf7';
}
.icon-lastfm21:before {
  content: '\eaf8';
}
.icon-delicious1:before {
  content: '\eaf9';
}
.icon-stumbleupon1:before {
  content: '\eafa';
}
.icon-stumbleupon21:before {
  content: '\eafb';
}
.icon-stackoverflow1:before {
  content: '\eafc';
}
.icon-pinterest1:before {
  content: '\eafd';
}
.icon-pinterest21:before {
  content: '\eafe';
}
.icon-xing1:before {
  content: '\eaff';
}
.icon-xing21:before {
  content: '\eb00';
}
.icon-flattr1:before {
  content: '\eb01';
}
.icon-foursquare1:before {
  content: '\eb02';
}
.icon-yelp1:before {
  content: '\eb03';
}
.icon-paypal1:before {
  content: '\eb04';
}
.icon-chrome1:before {
  content: '\eb05';
}
.icon-firefox1:before {
  content: '\eb06';
}
.icon-IE1:before {
  content: '\eb07';
}
.icon-edge1:before {
  content: '\eb08';
}
.icon-safari1:before {
  content: '\eb09';
}
.icon-opera1:before {
  content: '\eb0a';
}
.icon-file-pdf1:before {
  content: '\eb0b';
}
.icon-file-openoffice1:before {
  content: '\eb0c';
}
.icon-file-word1:before {
  content: '\eb0d';
}
.icon-file-excel1:before {
  content: '\eb0e';
}
.icon-libreoffice1:before {
  content: '\eb0f';
}
.icon-html-five1:before {
  content: '\eb10';
}
.icon-html-five21:before {
  content: '\eb11';
}
.icon-css31:before {
  content: '\eb12';
}
.icon-git1:before {
  content: '\eb13';
}
.icon-codepen1:before {
  content: '\eb14';
}
.icon-svg1:before {
  content: '\eb15';
}
.icon-IcoMoon1:before {
  content: '\eb16';
}
.icon-icon_App_Store_EN:before {
  content: '\eb17';
}
.icon-icon_App_Store:before {
  content: '\eb18';
}
.icon-icon_Google_Play_EN:before {
  content: '\eb19';
}
.icon-icon_Google_Play:before {
  content: '\eb1a';
}
.icon-icon_Local_download_EN:before {
  content: '\eb1b';
}
.icon-icon_Local_download:before {
  content: '\eb1c';
}
.icon-icon_Language_CN:before {
  content: '\eb1d';
}
.icon-icon_Language_EN:before {
  content: '\eb1e';
}
.icon-Icon_Amount1:before {
  content: '\eb1f';
}
.icon-Icon_Calendar1:before {
  content: '\eb20';
}
.icon-Icon_call1:before {
  content: '\eb21';
}
.icon-Icon_Camera1:before {
  content: '\eb22';
}
.icon-Icon_Cashier:before {
  content: '\eb23';
}
.icon-Icon_Chat1:before {
  content: '\eb24';
}
.icon-Icon_Credit-card-21:before {
  content: '\eb25';
}
.icon-Icon_Delivery1:before {
  content: '\eb26';
}
.icon-Icon_Discount1:before {
  content: '\eb27';
}
.icon-Icon_email1:before {
  content: '\eb28';
}
.icon-icon_Home1:before {
  content: '\eb29';
}
.icon-Icon_Location1:before {
  content: '\eb2a';
}
.icon-Icon_Notes1:before {
  content: '\eb2b';
}
.icon-Icon_Orders1:before {
  content: '\eb2c';
}
.icon-Icon_Products1:before {
  content: '\eb2d';
}
.icon-Icon_Receipt1:before {
  content: '\eb2e';
}
.icon-Icon_Report1:before {
  content: '\eb2f';
}
.icon-Icon_Stock1:before {
  content: '\eb30';
}
.icon-Icon_Tag1:before {
  content: '\eb31';
}
.icon-Icon_Team1:before {
  content: '\eb32';
}
.icon-Icon_Type1:before {
  content: '\eb33';
}
.icon-Logo1:before {
  content: '\eb34';
}
.icon-Icon_store-2:before {
  content: '\eb35';
}
.icon-Icon_parenting-2:before {
  content: '\eb36';
}
.icon-Icon_cart:before {
  content: '\eb37';
}
.icon-Icon_Download:before {
  content: '\eb38';
}
.icon-Icon_profile:before {
  content: '\eb39';
}
.icon-Icon_Search2:before {
  content: '\eb3a';
}
.icon-Symbol_Filter:before {
  content: '\eb3b';
}
.icon-uniEB3C:before {
  content: '\eb3c';
}
.icon-uniEB3D:before {
  content: '\eb3d';
}
.icon-prevIcon:before {
  content: '\eb3e';
}
.icon-nextIcon:before {
  content: '\eb3f';
}
.icon-surprise:before {
  content: '\eb40';
}
.icon-home:before {
  content: '\eb41';
}
.icon-home2:before {
  content: '\eb42';
}
.icon-home3:before {
  content: '\eb43';
}
.icon-office:before {
  content: '\eb44';
}
.icon-newspaper:before {
  content: '\eb45';
}
.icon-pencil:before {
  content: '\eb46';
}
.icon-pencil2:before {
  content: '\eb47';
}
.icon-quill:before {
  content: '\eb48';
}
.icon-pen:before {
  content: '\eb49';
}
.icon-blog:before {
  content: '\eb4a';
}
.icon-eyedropper:before {
  content: '\eb4b';
}
.icon-droplet:before {
  content: '\eb4c';
}
.icon-paint-format:before {
  content: '\eb4d';
}
.icon-image:before {
  content: '\eb4e';
}
.icon-images:before {
  content: '\eb4f';
}
.icon-camera:before {
  content: '\eb50';
}
.icon-headphones:before {
  content: '\eb51';
}
.icon-music:before {
  content: '\eb52';
}
.icon-play:before {
  content: '\eb53';
}
.icon-film:before {
  content: '\eb54';
}
.icon-video-camera:before {
  content: '\eb55';
}
.icon-dice:before {
  content: '\eb56';
}
.icon-pacman:before {
  content: '\eb57';
}
.icon-spades:before {
  content: '\eb58';
}
.icon-clubs:before {
  content: '\eb59';
}
.icon-diamonds:before {
  content: '\eb5a';
}
.icon-bullhorn:before {
  content: '\eb5b';
}
.icon-connection:before {
  content: '\eb5c';
}
.icon-podcast:before {
  content: '\eb5d';
}
.icon-feed:before {
  content: '\eb5e';
}
.icon-mic:before {
  content: '\eb5f';
}
.icon-book:before {
  content: '\eb60';
}
.icon-books:before {
  content: '\eb61';
}
.icon-library:before {
  content: '\eb62';
}
.icon-file-text:before {
  content: '\eb63';
}
.icon-profile:before {
  content: '\eb64';
}
.icon-file-empty:before {
  content: '\eb65';
}
.icon-files-empty:before {
  content: '\eb66';
}
.icon-file-text2:before {
  content: '\eb67';
}
.icon-file-picture:before {
  content: '\eb68';
}
.icon-file-music:before {
  content: '\eb69';
}
.icon-file-play:before {
  content: '\eb6a';
}
.icon-file-video:before {
  content: '\eb6b';
}
.icon-file-zip:before {
  content: '\eb6c';
}
.icon-copy:before {
  content: '\eb6d';
}
.icon-paste:before {
  content: '\eb6e';
}
.icon-stack:before {
  content: '\eb6f';
}
.icon-folder:before {
  content: '\eb70';
}
.icon-folder-open:before {
  content: '\eb71';
}
.icon-folder-plus:before {
  content: '\eb72';
}
.icon-folder-minus:before {
  content: '\eb73';
}
.icon-folder-download:before {
  content: '\eb74';
}
.icon-folder-upload:before {
  content: '\eb75';
}
.icon-price-tag:before {
  content: '\eb76';
}
.icon-price-tags:before {
  content: '\eb77';
}
.icon-barcode:before {
  content: '\eb78';
}
.icon-qrcode:before {
  content: '\eb79';
}
.icon-ticket:before {
  content: '\eb7a';
}
.icon-cart:before {
  content: '\eb7b';
}
.icon-coin-dollar:before {
  content: '\eb7c';
}
.icon-coin-euro:before {
  content: '\eb7d';
}
.icon-coin-pound:before {
  content: '\eb7e';
}
.icon-coin-yen:before {
  content: '\eb7f';
}
.icon-credit-card:before {
  content: '\eb80';
}
.icon-calculator:before {
  content: '\eb81';
}
.icon-lifebuoy:before {
  content: '\eb82';
}
.icon-phone:before {
  content: '\eb83';
}
.icon-phone-hang-up:before {
  content: '\eb84';
}
.icon-address-book:before {
  content: '\eb85';
}
.icon-envelop:before {
  content: '\eb86';
}
.icon-pushpin:before {
  content: '\eb87';
}
.icon-location:before {
  content: '\eb88';
}
.icon-location2:before {
  content: '\eb89';
}
.icon-compass:before {
  content: '\eb8a';
}
.icon-compass2:before {
  content: '\eb8b';
}
.icon-map:before {
  content: '\eb8c';
}
.icon-map2:before {
  content: '\eb8d';
}
.icon-history:before {
  content: '\eb8e';
}
.icon-clock:before {
  content: '\eb8f';
}
.icon-clock2:before {
  content: '\eb90';
}
.icon-alarm:before {
  content: '\eb91';
}
.icon-bell:before {
  content: '\eb92';
}
.icon-stopwatch:before {
  content: '\eb93';
}
.icon-calendar:before {
  content: '\eb94';
}
.icon-printer:before {
  content: '\eb95';
}
.icon-keyboard:before {
  content: '\eb96';
}
.icon-display:before {
  content: '\eb97';
}
.icon-laptop:before {
  content: '\eb98';
}
.icon-mobile:before {
  content: '\eb99';
}
.icon-mobile2:before {
  content: '\eb9a';
}
.icon-tablet:before {
  content: '\eb9b';
}
.icon-tv:before {
  content: '\eb9c';
}
.icon-drawer:before {
  content: '\eb9d';
}
.icon-drawer2:before {
  content: '\eb9e';
}
.icon-box-add:before {
  content: '\eb9f';
}
.icon-box-remove:before {
  content: '\eba0';
}
.icon-download:before {
  content: '\eba1';
}
.icon-upload:before {
  content: '\eba2';
}
.icon-floppy-disk:before {
  content: '\eba3';
}
.icon-drive:before {
  content: '\eba4';
}
.icon-database:before {
  content: '\eba5';
}
.icon-undo:before {
  content: '\eba6';
}
.icon-redo:before {
  content: '\eba7';
}
.icon-undo2:before {
  content: '\eba8';
}
.icon-redo2:before {
  content: '\eba9';
}
.icon-forward:before {
  content: '\ebaa';
}
.icon-reply:before {
  content: '\ebab';
}
.icon-bubble:before {
  content: '\ebac';
}
.icon-bubbles:before {
  content: '\ebad';
}
.icon-bubbles2:before {
  content: '\ebae';
}
.icon-bubble2:before {
  content: '\ebaf';
}
.icon-bubbles3:before {
  content: '\ebb0';
}
.icon-bubbles4:before {
  content: '\ebb1';
}
.icon-user:before {
  content: '\ebb2';
}
.icon-users:before {
  content: '\ebb3';
}
.icon-user-plus:before {
  content: '\ebb4';
}
.icon-user-minus:before {
  content: '\ebb5';
}
.icon-user-check:before {
  content: '\ebb6';
}
.icon-user-tie:before {
  content: '\ebb7';
}
.icon-quotes-left:before {
  content: '\ebb8';
}
.icon-quotes-right:before {
  content: '\ebb9';
}
.icon-hour-glass:before {
  content: '\ebba';
}
.icon-spinner:before {
  content: '\ebbb';
}
.icon-spinner2:before {
  content: '\ebbc';
}
.icon-spinner3:before {
  content: '\ebbd';
}
.icon-spinner4:before {
  content: '\ebbe';
}
.icon-spinner5:before {
  content: '\ebbf';
}
.icon-spinner6:before {
  content: '\ebc0';
}
.icon-spinner7:before {
  content: '\ebc1';
}
.icon-spinner8:before {
  content: '\ebc2';
}
.icon-spinner9:before {
  content: '\ebc3';
}
.icon-spinner10:before {
  content: '\ebc4';
}
.icon-spinner11:before {
  content: '\ebc5';
}
.icon-binoculars:before {
  content: '\ebc6';
}
.icon-search:before {
  content: '\ebc7';
}
.icon-zoom-in:before {
  content: '\ebc8';
}
.icon-zoom-out:before {
  content: '\ebc9';
}
.icon-enlarge:before {
  content: '\ebca';
}
.icon-shrink:before {
  content: '\ebcb';
}
.icon-enlarge2:before {
  content: '\ebcc';
}
.icon-shrink2:before {
  content: '\ebcd';
}
.icon-key:before {
  content: '\ebce';
}
.icon-key2:before {
  content: '\ebcf';
}
.icon-lock:before {
  content: '\ebd0';
}
.icon-unlocked:before {
  content: '\ebd1';
}
.icon-wrench:before {
  content: '\ebd2';
}
.icon-equalizer:before {
  content: '\ebd3';
}
.icon-equalizer2:before {
  content: '\ebd4';
}
.icon-cog:before {
  content: '\ebd5';
}
.icon-cogs:before {
  content: '\ebd6';
}
.icon-hammer:before {
  content: '\ebd7';
}
.icon-magic-wand:before {
  content: '\ebd8';
}
.icon-aid-kit:before {
  content: '\ebd9';
}
.icon-bug:before {
  content: '\ebda';
}
.icon-pie-chart:before {
  content: '\ebdb';
}
.icon-stats-dots:before {
  content: '\ebdc';
}
.icon-stats-bars:before {
  content: '\ebdd';
}
.icon-stats-bars2:before {
  content: '\ebde';
}
.icon-trophy:before {
  content: '\ebdf';
}
.icon-gift:before {
  content: '\ebe0';
}
.icon-glass:before {
  content: '\ebe1';
}
.icon-glass2:before {
  content: '\ebe2';
}
.icon-mug:before {
  content: '\ebe3';
}
.icon-spoon-knife:before {
  content: '\ebe4';
}
.icon-leaf:before {
  content: '\ebe5';
}
.icon-rocket:before {
  content: '\ebe6';
}
.icon-meter:before {
  content: '\ebe7';
}
.icon-meter2:before {
  content: '\ebe8';
}
.icon-hammer2:before {
  content: '\ebe9';
}
.icon-fire:before {
  content: '\ebea';
}
.icon-lab:before {
  content: '\ebeb';
}
.icon-magnet:before {
  content: '\ebec';
}
.icon-bin:before {
  content: '\ebed';
}
.icon-bin2:before {
  content: '\ebee';
}
.icon-briefcase:before {
  content: '\ebef';
}
.icon-airplane:before {
  content: '\ebf0';
}
.icon-truck:before {
  content: '\ebf1';
}
.icon-road:before {
  content: '\ebf2';
}
.icon-accessibility:before {
  content: '\ebf3';
}
.icon-target:before {
  content: '\ebf4';
}
.icon-shield:before {
  content: '\ebf5';
}
.icon-power:before {
  content: '\ebf6';
}
.icon-switch:before {
  content: '\ebf7';
}
.icon-power-cord:before {
  content: '\ebf8';
}
.icon-clipboard:before {
  content: '\ebf9';
}
.icon-list-numbered:before {
  content: '\ebfa';
}
.icon-list:before {
  content: '\ebfb';
}
.icon-list2:before {
  content: '\ebfc';
}
.icon-tree:before {
  content: '\ebfd';
}
.icon-menu:before {
  content: '\ebfe';
}
.icon-menu2:before {
  content: '\ebff';
}
.icon-menu3:before {
  content: '\ec00';
}
.icon-menu4:before {
  content: '\ec01';
}
.icon-cloud:before {
  content: '\ec02';
}
.icon-cloud-download:before {
  content: '\ec03';
}
.icon-cloud-upload:before {
  content: '\ec04';
}
.icon-cloud-check:before {
  content: '\ec05';
}
.icon-download2:before {
  content: '\ec06';
}
.icon-upload2:before {
  content: '\ec07';
}
.icon-download3:before {
  content: '\ec08';
}
.icon-upload3:before {
  content: '\ec09';
}
.icon-sphere:before {
  content: '\ec0a';
}
.icon-earth:before {
  content: '\ec0b';
}
.icon-link:before {
  content: '\ec0c';
}
.icon-flag:before {
  content: '\ec0d';
}
.icon-attachment:before {
  content: '\ec0e';
}
.icon-eye:before {
  content: '\ec0f';
}
.icon-eye-plus:before {
  content: '\ec10';
}
.icon-eye-minus:before {
  content: '\ec11';
}
.icon-eye-blocked:before {
  content: '\ec12';
}
.icon-bookmark:before {
  content: '\ec13';
}
.icon-bookmarks:before {
  content: '\ec14';
}
.icon-sun:before {
  content: '\ec15';
}
.icon-contrast:before {
  content: '\ec16';
}
.icon-brightness-contrast:before {
  content: '\ec17';
}
.icon-star-empty:before {
  content: '\ec18';
}
.icon-star-half:before {
  content: '\ec19';
}
.icon-star-full:before {
  content: '\ec1a';
}
.icon-heart:before {
  content: '\ec1b';
}
.icon-heart-broken:before {
  content: '\ec1c';
}
.icon-man:before {
  content: '\ec1d';
}
.icon-woman:before {
  content: '\ec1e';
}
.icon-man-woman:before {
  content: '\ec1f';
}
.icon-happy:before {
  content: '\ec20';
}
.icon-happy2:before {
  content: '\ec21';
}
.icon-smile:before {
  content: '\ec22';
}
.icon-smile2:before {
  content: '\ec23';
}
.icon-tongue:before {
  content: '\ec24';
}
.icon-tongue2:before {
  content: '\ec25';
}
.icon-sad:before {
  content: '\ec26';
}
.icon-sad2:before {
  content: '\ec27';
}
.icon-wink:before {
  content: '\ec28';
}
.icon-wink2:before {
  content: '\ec29';
}
.icon-grin:before {
  content: '\ec2a';
}
.icon-grin2:before {
  content: '\ec2b';
}
.icon-cool:before {
  content: '\ec2c';
}
.icon-cool2:before {
  content: '\ec2d';
}
.icon-angry:before {
  content: '\ec2e';
}
.icon-angry2:before {
  content: '\ec2f';
}
.icon-evil:before {
  content: '\ec30';
}
.icon-evil2:before {
  content: '\ec31';
}
.icon-shocked:before {
  content: '\ec32';
}
.icon-shocked2:before {
  content: '\ec33';
}
.icon-baffled:before {
  content: '\ec34';
}
.icon-baffled2:before {
  content: '\ec35';
}
.icon-confused:before {
  content: '\ec36';
}
.icon-confused2:before {
  content: '\ec37';
}
.icon-neutral:before {
  content: '\ec38';
}
.icon-neutral2:before {
  content: '\ec39';
}
.icon-hipster:before {
  content: '\ec3a';
}
.icon-hipster2:before {
  content: '\ec3b';
}
.icon-wondering:before {
  content: '\ec3c';
}
.icon-wondering2:before {
  content: '\ec3d';
}
.icon-sleepy:before {
  content: '\ec3e';
}
.icon-sleepy2:before {
  content: '\ec3f';
}
.icon-frustrated:before {
  content: '\ec40';
}
.icon-frustrated2:before {
  content: '\ec41';
}
.icon-crying:before {
  content: '\ec42';
}
.icon-crying2:before {
  content: '\ec43';
}
.icon-point-up:before {
  content: '\ec44';
}
.icon-point-right:before {
  content: '\ec45';
}
.icon-point-down:before {
  content: '\ec46';
}
.icon-point-left:before {
  content: '\ec47';
}
.icon-warning:before {
  content: '\ec48';
}
.icon-notification:before {
  content: '\ec49';
}
.icon-question:before {
  content: '\ec4a';
}
.icon-plus:before {
  content: '\ec4b';
}
.icon-minus:before {
  content: '\ec4c';
}
.icon-info:before {
  content: '\ec4d';
}
.icon-cancel-circle:before {
  content: '\ec4e';
}
.icon-blocked:before {
  content: '\ec4f';
}
.icon-cross:before {
  content: '\ec50';
}
.icon-checkmark:before {
  content: '\ec51';
}
.icon-checkmark2:before {
  content: '\ec52';
}
.icon-spell-check:before {
  content: '\ec53';
}
.icon-enter:before {
  content: '\ec54';
}
.icon-exit:before {
  content: '\ec55';
}
.icon-play2:before {
  content: '\ec56';
}
.icon-pause:before {
  content: '\ec57';
}
.icon-stop:before {
  content: '\ec58';
}
.icon-previous:before {
  content: '\ec59';
}
.icon-next:before {
  content: '\ec5a';
}
.icon-backward:before {
  content: '\ec5b';
}
.icon-forward2:before {
  content: '\ec5c';
}
.icon-play3:before {
  content: '\ec5d';
}
.icon-pause2:before {
  content: '\ec5e';
}
.icon-stop2:before {
  content: '\ec5f';
}
.icon-backward2:before {
  content: '\ec60';
}
.icon-forward3:before {
  content: '\ec61';
}
.icon-first:before {
  content: '\ec62';
}
.icon-last:before {
  content: '\ec63';
}
.icon-previous2:before {
  content: '\ec64';
}
.icon-next2:before {
  content: '\ec65';
}
.icon-eject:before {
  content: '\ec66';
}
.icon-volume-high:before {
  content: '\ec67';
}
.icon-volume-medium:before {
  content: '\ec68';
}
.icon-volume-low:before {
  content: '\ec69';
}
.icon-volume-mute:before {
  content: '\ec6a';
}
.icon-volume-mute2:before {
  content: '\ec6b';
}
.icon-volume-increase:before {
  content: '\ec6c';
}
.icon-volume-decrease:before {
  content: '\ec6d';
}
.icon-loop:before {
  content: '\ec6e';
}
.icon-loop2:before {
  content: '\ec6f';
}
.icon-infinite:before {
  content: '\ec70';
}
.icon-shuffle:before {
  content: '\ec71';
}
.icon-arrow-up-left:before {
  content: '\ec72';
}
.icon-arrow-up:before {
  content: '\ec73';
}
.icon-arrow-up-right:before {
  content: '\ec74';
}
.icon-arrow-right:before {
  content: '\ec75';
}
.icon-arrow-down-right:before {
  content: '\ec76';
}
.icon-arrow-down:before {
  content: '\ec77';
}
.icon-arrow-down-left:before {
  content: '\ec78';
}
.icon-arrow-left:before {
  content: '\ec79';
}
.icon-arrow-up-left2:before {
  content: '\ec7a';
}
.icon-arrow-up2:before {
  content: '\ec7b';
}
.icon-arrow-up-right2:before {
  content: '\ec7c';
}
.icon-arrow-right2:before {
  content: '\ec7d';
}
.icon-arrow-down-right2:before {
  content: '\ec7e';
}
.icon-arrow-down2:before {
  content: '\ec7f';
}
.icon-arrow-down-left2:before {
  content: '\ec80';
}
.icon-arrow-left2:before {
  content: '\ec81';
}
.icon-circle-up:before {
  content: '\ec82';
}
.icon-circle-right:before {
  content: '\ec83';
}
.icon-circle-down:before {
  content: '\ec84';
}
.icon-circle-left:before {
  content: '\ec85';
}
.icon-tab:before {
  content: '\ec86';
}
.icon-move-up:before {
  content: '\ec87';
}
.icon-move-down:before {
  content: '\ec88';
}
.icon-sort-alpha-asc:before {
  content: '\ec89';
}
.icon-sort-alpha-desc:before {
  content: '\ec8a';
}
.icon-sort-numeric-asc:before {
  content: '\ec8b';
}
.icon-sort-numberic-desc:before {
  content: '\ec8c';
}
.icon-sort-amount-asc:before {
  content: '\ec8d';
}
.icon-sort-amount-desc:before {
  content: '\ec8e';
}
.icon-command:before {
  content: '\ec8f';
}
.icon-shift:before {
  content: '\ec90';
}
.icon-ctrl:before {
  content: '\ec91';
}
.icon-opt:before {
  content: '\ec92';
}
.icon-checkbox-checked:before {
  content: '\ec93';
}
.icon-checkbox-unchecked:before {
  content: '\ec94';
}
.icon-radio-checked:before {
  content: '\ec95';
}
.icon-radio-checked2:before {
  content: '\ec96';
}
.icon-radio-unchecked:before {
  content: '\ec97';
}
.icon-crop:before {
  content: '\ec98';
}
.icon-make-group:before {
  content: '\ec99';
}
.icon-ungroup:before {
  content: '\ec9a';
}
.icon-scissors:before {
  content: '\ec9b';
}
.icon-filter:before {
  content: '\ec9c';
}
.icon-font:before {
  content: '\ec9d';
}
.icon-ligature:before {
  content: '\ec9e';
}
.icon-ligature2:before {
  content: '\ec9f';
}
.icon-text-height:before {
  content: '\eca0';
}
.icon-text-width:before {
  content: '\eca1';
}
.icon-font-size:before {
  content: '\eca2';
}
.icon-bold:before {
  content: '\eca3';
}
.icon-underline:before {
  content: '\eca4';
}
.icon-italic:before {
  content: '\eca5';
}
.icon-strikethrough:before {
  content: '\eca6';
}
.icon-omega:before {
  content: '\eca7';
}
.icon-sigma:before {
  content: '\eca8';
}
.icon-page-break:before {
  content: '\eca9';
}
.icon-superscript:before {
  content: '\ecaa';
}
.icon-subscript:before {
  content: '\ecab';
}
.icon-superscript2:before {
  content: '\ecac';
}
.icon-subscript2:before {
  content: '\ecad';
}
.icon-text-color:before {
  content: '\ecae';
}
.icon-pagebreak:before {
  content: '\ecaf';
}
.icon-clear-formatting:before {
  content: '\ecb0';
}
.icon-table:before {
  content: '\ecb1';
}
.icon-table2:before {
  content: '\ecb2';
}
.icon-insert-template:before {
  content: '\ecb3';
}
.icon-pilcrow:before {
  content: '\ecb4';
}
.icon-ltr:before {
  content: '\ecb5';
}
.icon-rtl:before {
  content: '\ecb6';
}
.icon-section:before {
  content: '\ecb7';
}
.icon-paragraph-left:before {
  content: '\ecb8';
}
.icon-paragraph-center:before {
  content: '\ecb9';
}
.icon-paragraph-right:before {
  content: '\ecba';
}
.icon-paragraph-justify:before {
  content: '\ecbb';
}
.icon-indent-increase:before {
  content: '\ecbc';
}
.icon-indent-decrease:before {
  content: '\ecbd';
}
.icon-share:before {
  content: '\ecbe';
}
.icon-new-tab:before {
  content: '\ecbf';
}
.icon-embed:before {
  content: '\ecc0';
}
.icon-embed2:before {
  content: '\ecc1';
}
.icon-terminal:before {
  content: '\ecc2';
}
.icon-share2:before {
  content: '\ecc3';
}
.icon-mail:before {
  content: '\ecc4';
}
.icon-mail2:before {
  content: '\ecc5';
}
.icon-mail3:before {
  content: '\ecc6';
}
.icon-mail4:before {
  content: '\ecc7';
}
.icon-amazon:before {
  content: '\ecc8';
}
.icon-google:before {
  content: '\ecc9';
}
.icon-google2:before {
  content: '\ecca';
}
.icon-google3:before {
  content: '\eccb';
}
.icon-google-plus:before {
  content: '\eccc';
}
.icon-google-plus2:before {
  content: '\eccd';
}
.icon-google-plus3:before {
  content: '\ecce';
}
.icon-hangouts:before {
  content: '\eccf';
}
.icon-google-drive:before {
  content: '\ecd0';
}
.icon-facebook:before {
  content: '\ecd1';
}
.icon-facebook2:before {
  content: '\ecd2';
}
.icon-instagram:before {
  content: '\ecd3';
}
.icon-whatsapp:before {
  content: '\ecd4';
}
.icon-spotify:before {
  content: '\ecd5';
}
.icon-telegram:before {
  content: '\ecd6';
}
.icon-twitter:before {
  content: '\ecd7';
}
.icon-vine:before {
  content: '\ecd8';
}
.icon-vk:before {
  content: '\ecd9';
}
.icon-renren:before {
  content: '\ecda';
}
.icon-sina-weibo:before {
  content: '\ecdb';
}
.icon-rss:before {
  content: '\ecdc';
}
.icon-rss2:before {
  content: '\ecdd';
}
.icon-youtube:before {
  content: '\ecde';
}
.icon-youtube2:before {
  content: '\ecdf';
}
.icon-twitch:before {
  content: '\ece0';
}
.icon-vimeo:before {
  content: '\ece1';
}
.icon-vimeo2:before {
  content: '\ece2';
}
.icon-lanyrd:before {
  content: '\ece3';
}
.icon-flickr:before {
  content: '\ece4';
}
.icon-flickr2:before {
  content: '\ece5';
}
.icon-flickr3:before {
  content: '\ece6';
}
.icon-flickr4:before {
  content: '\ece7';
}
.icon-dribbble:before {
  content: '\ece8';
}
.icon-behance:before {
  content: '\ece9';
}
.icon-behance2:before {
  content: '\ecea';
}
.icon-deviantart:before {
  content: '\eceb';
}
.icon-500px:before {
  content: '\ecec';
}
.icon-steam:before {
  content: '\eced';
}
.icon-steam2:before {
  content: '\ecee';
}
.icon-dropbox:before {
  content: '\ecef';
}
.icon-onedrive:before {
  content: '\ecf0';
}
.icon-github:before {
  content: '\ecf1';
}
.icon-npm:before {
  content: '\ecf2';
}
.icon-basecamp:before {
  content: '\ecf3';
}
.icon-trello:before {
  content: '\ecf4';
}
.icon-wordpress:before {
  content: '\ecf5';
}
.icon-joomla:before {
  content: '\ecf6';
}
.icon-ello:before {
  content: '\ecf7';
}
.icon-blogger:before {
  content: '\ecf8';
}
.icon-blogger2:before {
  content: '\ecf9';
}
.icon-tumblr:before {
  content: '\ecfa';
}
.icon-tumblr2:before {
  content: '\ecfb';
}
.icon-yahoo:before {
  content: '\ecfc';
}
.icon-yahoo2:before {
  content: '\ecfd';
}
.icon-tux:before {
  content: '\ecfe';
}
.icon-appleinc:before {
  content: '\ecff';
}
.icon-finder:before {
  content: '\ed00';
}
.icon-android:before {
  content: '\ed01';
}
.icon-windows:before {
  content: '\ed02';
}
.icon-windows8:before {
  content: '\ed03';
}
.icon-soundcloud:before {
  content: '\ed04';
}
.icon-soundcloud2:before {
  content: '\ed05';
}
.icon-skype:before {
  content: '\ed06';
}
.icon-reddit:before {
  content: '\ed07';
}
.icon-hackernews:before {
  content: '\ed08';
}
.icon-wikipedia:before {
  content: '\ed09';
}
.icon-linkedin:before {
  content: '\ed0a';
}
.icon-linkedin2:before {
  content: '\ed0b';
}
.icon-lastfm:before {
  content: '\ed0c';
}
.icon-lastfm2:before {
  content: '\ed0d';
}
.icon-delicious:before {
  content: '\ed0e';
}
.icon-stumbleupon:before {
  content: '\ed0f';
}
.icon-stumbleupon2:before {
  content: '\ed10';
}
.icon-stackoverflow:before {
  content: '\ed11';
}
.icon-pinterest:before {
  content: '\ed12';
}
.icon-pinterest2:before {
  content: '\ed13';
}
.icon-xing:before {
  content: '\ed14';
}
.icon-xing2:before {
  content: '\ed15';
}
.icon-flattr:before {
  content: '\ed16';
}
.icon-foursquare:before {
  content: '\ed17';
}
.icon-yelp:before {
  content: '\ed18';
}
.icon-paypal:before {
  content: '\ed19';
}
.icon-chrome:before {
  content: '\ed1a';
}
.icon-firefox:before {
  content: '\ed1b';
}
.icon-IE:before {
  content: '\ed1c';
}
.icon-edge:before {
  content: '\ed1d';
}
.icon-safari:before {
  content: '\ed1e';
}
.icon-opera:before {
  content: '\ed1f';
}
.icon-file-pdf:before {
  content: '\ed20';
}
.icon-file-openoffice:before {
  content: '\ed21';
}
.icon-file-word:before {
  content: '\ed22';
}
.icon-file-excel:before {
  content: '\ed23';
}
.icon-libreoffice:before {
  content: '\ed24';
}
.icon-html-five:before {
  content: '\ed25';
}
.icon-html-five2:before {
  content: '\ed26';
}
.icon-css3:before {
  content: '\ed27';
}
.icon-git:before {
  content: '\ed28';
}
.icon-codepen:before {
  content: '\ed29';
}
.icon-svg:before {
  content: '\ed2a';
}
.icon-IcoMoon:before {
  content: '\ed2b';
}
