.loader {
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.root {
  @apply fixed bg-primary text-primary flex items-center inset-0 z-50 justify-center;
  background-color: rgba(0, 0, 0, 0.35);
}

.modal {
  @apply bg-transparent ;
}

.modal:focus {
  @apply outline-none;
}
