.root {
  @apply fixed bg-primary text-primary flex items-center inset-0 z-50 justify-center;
  background-color: rgba(0, 0, 0, 0.35);
}

.modal {
  @apply bg-primary py-2 px-4 border border-accents-2 rounded-xl w-5/6;
}

.modal:focus {
  @apply outline-none;
}
